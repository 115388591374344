.comparisonTable {
  margin-top: 50px;
  border-collapse: collapse;

  th, td {
    padding: 10px 20px !important;
    @media (max-width: 768px) {
      padding: 10px 10px !important;
      &:not(:first-of-type) {
        padding: 10px 3px !important;
      }
    }
  }

  th {

    &:first-of-type {
      text-align: left !important;
      border-top-left-radius: 5px;
      color: var(--secondary-font-color);
    }

    text-transform: capitalize;
    text-align: center;
    background-color: var(--primary-font-color);
    font-size: 1.4rem;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }

    img {
      height: 1.4rem;
      display: inline-block;
      object-fit: contain;
    }

    &:last-of-type {
      border-top-right-radius: 5px;
    }
  }

  td {
    border: 1px solid #E5E5E5;
    padding: 0.5rem;
    color: var(--primary-font-color);
    font-weight: 700;
    font-size: 1.1rem;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }

    &:not(:first-of-type) {
      text-align: center;
      svg {
        display: inline-block;
        transform: scale(1.5);
      }
    }
    &:nth-child(2) {
      background-color: #41A0A619;
    }
    &:nth-child(3) {
      background-color: #A4A4A419;
    }
  }
}