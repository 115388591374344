@import url('https://fonts.cdnfonts.com/css/gotham');
@import url('https://fonts.cdnfonts.com/css/gotham-ssm');

html {
  scroll-behavior: smooth;
  background-color: #f9f9f9;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
}

:root {
  --primary-font-color: #002D4B;
  --primary-cloud-color: #41a0a6;
  --primary-onprem-color: #E43A44;
  --secondary-cloud-color: #697f8d;
  --secondary-onprem-color: #697f8d;
  --secondary-font-color: #F9F9F9;
}

#root {
    color: var(--primary-font-color);
    font-family: Gotham, Arial, sans-serif;
    margin: 0;
    background-color: var(--secondary-font-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/* Internet Explorer */
:-ms-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/* Edge */
::-ms-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/* Firefox */
::-moz-placeholder {
  color: inherit;
  opacity: 0.54;
}

.mantine-Pagination-control[data-active] {
  background-color: #41a0a6 !important;
}

.primaryButton {
  background-color: var(--primary-font-color) !important;
  color: var(--secondary-font-color) !important;
}

.errorButton {
  background-color: #DE6711 !important;
  color: var(--secondary-font-color) !important;
}

.reportButton {
  background-color: var(--primary-font-color) !important;
  color: var(--secondary-font-color) !important;
}

button.shadow {
  box-shadow: 0.1em 0.1em 0.3em rgba(0, 0, 0, 0.3);
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/Inconsolata-variable.ttf) format('truetype');
}


.pulse {
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0,45,75,0.4);
  }
  50% {
    box-shadow: 0px 0px 11px 0px rgba(0,45,75,0.4);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,45,75,0.4);
  }
}

.customCard {
  border-radius: 20px !important;
  background-color: #F2F3F4 !important;
  border: 1px solid #ABCADFCC !important;
  .server, .server:first-child {
    color: var(--primary-font-color) !important;
    border-radius: 9px !important;
    background-color: #F9F9F9 !important;
    border: 1px solid #ABCADF !important;
  }
}

.countdown {
  font-size: 0.8em;
}

.privacyPolicy:hover {
  text-decoration: underline;
}

.alertIcon {
  -webkit-margin-end: initial !important;
  margin-inline-end: initial !important;
  svg {
    vertical-align: initial;
    width: initial;
    height: initial;
  }
}

.no-border {
  border: none !important;
  td {
    border: none !important;
  }
}