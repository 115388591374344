.tab {
  font-weight: 800 !important;
  text-align: center !important;
  font-size: 1em !important;
  border-bottom: 5px solid transparent !important;
  @media (max-width: 768px) {
    font-size: 0.9em !important;
  }
  &[aria-selected=true], &[data-selected] {
    border-color: var(--primary-cloud-color) !important;
    --tabs-color: var(--primary-cloud-color) !important;
    border-bottom: 5px solid !important;
  }
}