.navItem {
  font-family: "Gotham SSm", Arial, sans-serif;
  font-size: 1.25rem;
  transition: color .2s ease;
  color: #5c5e62;

  div {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    padding: 1rem;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.active, :hover > & {
    color: #222;
  }

  &.active {
    div {
      background: #eee;
    }
  }
}