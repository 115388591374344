.selectReadOnly {
  div > div:last-of-type {
    display: none;
  }
}

.select {
  input {
    caret-color: transparent;
  }
}