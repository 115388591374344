@media (max-width: 768px) {
  header button {
    padding: 15px;
  }

  .textContainer {
    h1 {
      font-size: 2em !important;
    }
  }
}

.textContainer {
  height: 100%;
  max-width: 900px;
  padding-top: 100px;
  margin: auto;

  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    line-height: 1;
    font-family: Gotham Ssm, Arial, sans-serif;
    font-size: 4em;
    color: white;
    margin-bottom: 20px;
  }
}

.chevronDown {
  margin-top: 2rem;
  display: inline-block;
  text-decoration: none;
  color: white;
}

.landing {
  height: 100vh;
  background-image: url('../../assets/background.png');
  background-size: cover;
  background-position-x: center;
  button {
    background-color: rgba(0,0,0,0.2);
    color: white;
    border: 3px solid white !important;
    border-radius: 5px;
    padding: 5px 40px;
    font-size: 1.6rem;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    margin: 40px 20px;

    @media (max-width: 768px) {
      margin: 20px 10px;
    }

    &:hover {
      background-color: #ffffff;
      color: black;
    }
  }
}