* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.headerImage {
  width: auto;
  height: auto;
  max-height: 1.75rem;
  max-width: initial;
}

.disableShadow {
  background-color: transparent;
  box-shadow: none !important;
}

.subLogo, .mainLogo {
  background-repeat: no-repeat;
  background-size: contain;
  width: 131px;
}

.subLogo {
  height: 17px;
  background-image: url('../../assets/sublogo_black.svg');
}

.mainLogo {
  height: 35px;
  background-image: url('../../assets/logo.svg');
}

.landingMain {
  display: flex;
  flex-direction: column;
}

.landingMain > section {
  max-width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.navItem {
  font-family: Gotham SSm, Arial, sans-serif;
  text-decoration: none;
  margin-right: 20px;
  font-size: 20px;
  --hover-color: #8b8b8b;
  &:hover {
    color: var(--hover-color) !important;
  }
}

header {
  position: sticky !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &.extendedHeader > nav {
    position: absolute;
    background-color: #FFFFFFB2;
    top: 0;
    left: 0;
    right: 0;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 5vw;
    backdrop-filter: blur(10px);
  }

  nav.navigation {
    box-shadow: 0 0 20px 0 #BFBFBF;
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .globe {
      display: flex;
      align-items: center;
    }
  }
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  cursor: pointer;
  place-items: flex-end;
  span {
    display: block;
    background-color: black;
    height: 3px;
    &:nth-child(1) {
      width: 30px;
    }
    &:nth-child(2) {
      width: 22px;
    }
    &:nth-child(3) {
      width: 14px;
    }
  }
}

@media (max-width: 768px) {
  header.extendedHeader {
      nav {
        top: 10px;
        left: initial;
        right: 10px;
        border-radius: 100%;
      }
     .logoContainer {
       display: none;
     }
  }

  header:not(.extendedHeader) {
    .logoContainer {
      display: block;
      visibility: hidden;
    }
  }
  .menu {
    display: none !important;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    width: 100%;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-items: center;
  }

  .hamburger {
    display: flex;
  }
}

main {
  background-color: #f9f9f9;
}

footer.landing-footer {
  padding: 4em;
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
    footer.landing-footer {
        padding: 1em;
    }
}