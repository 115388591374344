.name {
  font-weight: 600;
}

.jobTitle {
  font-size: 0.8em;
}

.stars {
  img {
    display: inline-block;
  }
}

.review {
  min-width: 30ch;
  max-width: 45ch;
}

.text {
  margin-bottom: 1ch;
}