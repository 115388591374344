.select {
  position: absolute;
  & > div{
    & > div:first-of-type{
      display: none;
    }
    & > div:last-of-type{
      right: 100%;
      width: max-content;
      div {
        right: -20px;
        min-width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .select {
    & > div{
      & > div:last-of-type{
        left: 100%;
        width: max-content;
        div {
          left: -20px;
          min-width: 100%;
        }
      }
    }
  }
}